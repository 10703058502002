import React, { useEffect, useState } from "react"
import MultiForm from "../../../form/multi-forms/MultiForm"
// import * as Balancer from 'react-wrap-balancer'

const Step1: React.FC = () => {
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div className="mt-3">
            <div className="rounded-md border p-5">
              <div className="border-b py-3">
                <div className="flex justify-between items-center mb-2 ">
                  <div className="font-bold text-lg">
                    No monthly subscription
                  </div>
                  <div>
                    <div className="bg-green-100 text-green-900 p-1 rounded-md text-sm font-bold border border-green-300">
                      FREE
                    </div>
                  </div>
                </div>
                <div>
                  Fresha is the only subscription-free software in the beauty
                  and wellness industry!
                  <br />
                  No contract or commitments, you can cancel at any time.
                </div>
              </div>

              <div className="border-b py-3">
                <div className="flex justify-between items-center mb-2 ">
                  <div className="font-bold text-lg">No payout fees</div>
                  <div>
                    <div className="bg-green-100 text-green-900 p-1 rounded-md text-sm font-bold border border-green-300">
                      FREE
                    </div>
                  </div>
                </div>
                <div>
                  Get paid the very next day with free daily payouts, no more
                  waiting.
                </div>
              </div>

              <div className="border-b py-3">
                <div className="flex justify-between items-center mb-2 ">
                  <div className="font-bold text-lg">
                    Payment processing fee
                  </div>
                  <div>
                    <div className="bg-yellow-100 text-yellow-900 p-1 rounded-md text-sm font-bold border border-yellow-300">
                      1.29% + 26$
                    </div>
                  </div>
                </div>
                <div>
                  One processing fee per day payment transaction across all
                  payment methods.
                </div>
              </div>

              <div className="py-3">
                <div className="text-gray-400">
                  Rate are exclusive of 10% sales TAX.
                </div>
                <div className="text-blue-400">
                  Learn more about our fees and commissions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step2: React.FC = () => {
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div>
            <div className="my-3 text-center">
              Follow our simple steps to begin processing payments with Fresha
            </div>
            <div className="mt-3 border rounded-md grid grid-cols-12 pr-8 py-2 gap-y-5 gap-x-4 max-w-[700px]">
              <div className="flex justify-end">
                <div className="bg-blue-600 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  1
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Set up your billing details
                </div>
                <div className="text-justify">
                  Get ready to use Fresha's paid features by entering your
                  credit card and billing information
                </div>
              </div>

              <div className="flex justify-end">
                <div className="bg-blue-600 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  2
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Enable payouts to your bank account
                </div>
                <div className="text-justify">
                  Enter your bank account details and account holder
                  information. Our team will quickly verify your details before
                  we begin to payouts funds directly to you each day.
                </div>
              </div>

              <div className="flex justify-end">
                <div className="bg-blue-600 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  3
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Choose how clients pay for appointments
                </div>
                <div className="text-justify">
                  Decide if you want clients to pay the full or partial amount
                  of the appointment price at the time of booking. You can also
                  choose to charge your clients for no-shows and late
                  collections.
                </div>
              </div>

              <div className="flex justify-end">
                <div className="bg-blue-600 p-3 rounded-full flex justify-center items-center text-white w-4 h-4 font-bold">
                  4
                </div>
              </div>
              <div className="col-span-11">
                <div className="font-bold text-lg ">
                  Start collecting payments from clients
                </div>
                <div className="text-justify">
                  You can begin collecting payments at checkout by charging
                  saved credit cards, sending secure payment link via text
                  sharing QR code. You can also order an in-store payment
                  terminal service.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step3: React.FC = () => {
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div>
            {/* <div className="my-3 text-center">Follow our simple steps to begin processing payments with Fresha</div> */}
            <div className="mt-3 border rounded-md grid grid-cols-12 p-8 py-4 gap-y-5 gap-x-4 max-w-[700px] min-w-[700px] gap-2">
              <div className="col-span-12">
                <div className="font-bold text-lg">Business name</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your business name"
                  />
                </div>
              </div>

              <div className="col-span-6">
                <div className="font-bold text-lg">First Name</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your first name"
                  />
                </div>
              </div>

              <div className="col-span-6">
                <div className="font-bold text-lg">Last Name</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your last name"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">Address</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your address"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">City</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your city"
                  />
                </div>
              </div>

              <div className="col-span-6">
                <div className="font-bold text-lg">State</div>
                <div>
                  <select className="outline-none border p-3 w-full capitalize rounded-sm mt-1">
                    <option disabled={true} value="">
                      --Choose and option--
                    </option>
                    <option>California</option>

                    <option>California</option>

                    <option>California</option>
                  </select>
                </div>
              </div>

              <div className="col-span-6">
                <div className="font-bold text-lg">Postal Code</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your postcode"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">ABN Code</div>
                <div>
                  <input className="outline-none border p-3 w-full capitalize rounded-sm mt-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step4: React.FC = () => {
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="flex justify-center">
          <div>
            {/* <div className="my-3 text-center">Follow our simple steps to begin processing payments with Fresha</div> */}
            <div className="mt-3 border rounded-md grid grid-cols-12 p-8 py-4 gap-y-5 gap-x-4 max-w-[700px] min-w-[700px] gap-2">
              <div className="col-span-12">
                <div className="font-bold text-lg">Company name</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your company name"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">Entity name</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your entity name"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">
                  Traditional name{" "}
                  <span className="text-gray-300 font-thin">(Optional)</span>
                </div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your traditional name"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">Street</div>
                <div>
                  <input className="outline-none border p-3 w-full capitalize rounded-sm mt-1" />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">Address</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter address"
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-lg">City</div>
                <div>
                  <input
                    className="outline-none border p-3 w-full capitalize rounded-sm mt-1"
                    placeholder="Enter your city"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step5: React.FC = () => {
  const [selected, setSelected] = useState(0)
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="my-3 text-center">
          Tell us how many people own <b>nail cloud.</b> This will determine the
          details you'll need to provide in next few step.
        </div>
        <div className="flex justify-center">
          <div>
            <div className="grid grid-cols-12 gap-y-4">
              <div
                className={`col-span-12 max-w-[700px] rounded border p-4 ${
                  selected === 0 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(0)
                }}
              >
                <div className="font-bold text-lg ">
                  Single owner of the business
                </div>
                <div className="text-gray-300">
                  1 person is responsible for signing and making decisions on
                  behalf of the business and is only shareholder.
                </div>
              </div>
              <div
                className={`col-span-12 max-w-[700px] rounded border p-4 ${
                  selected === 1 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(1)
                }}
              >
                <div className="font-bold text-lg ">
                  There are multiple shareholders
                </div>
                <div className="text-gray-300">
                  Multiple people own more than 25% of the business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step6: React.FC = () => {
  const [selected, setSelected] = useState(0)
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="my-3 text-center">
          Select one of the options below and upload a proof of ID.{" "}
          <span className="text-blue-400">See photo guidelines.</span>.
        </div>
        <div className="flex justify-center">
          <div>
            <div className="grid grid-cols-12 gap-y-4">
              <div
                className={`col-span-12 max-w-[700px] min-w-[700px] rounded border p-4 ${
                  selected === 0 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(0)
                }}
              >
                <div className="font-bold text-lg ">Passport</div>
                <div className="text-gray-300">
                  Upload copy of your valid passport
                </div>
              </div>
              <div
                className={`col-span-12 max-w-[700px] min-w-[700px] rounded border p-4 ${
                  selected === 1 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(1)
                }}
              >
                <div className="font-bold text-lg ">Driving license</div>
                <div className="text-gray-300">
                  Upload a copy of your valid driving license
                </div>
              </div>

              <div
                className={`col-span-12 max-w-[700px] min-w-[700px] rounded border p-4 ${
                  selected === 2 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(2)
                }}
              >
                <div className="font-bold text-lg ">Identity card</div>
                <div className="text-gray-300">
                  Upload a copy of your valid identity card
                </div>
              </div>

              <div
                className={`col-span-12 max-w-[700px] bg-yellow-100 rounded border p-4 border-none  cursor-pointer`}
                onClick={() => {
                  setSelected(1)
                }}
              >
                <div className="font-bold text-lg ">
                  Why do we need a proof of ID?
                </div>
                <div className="">
                  We recommend to ensure your account is secure and need this
                  document to verify all company representatives's identity.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const UploadPassport: React.FC = () => {
  const [selected, setSelected] = useState(0)
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="my-3 text-center">
          Select one of the options below and upload a proof of ID.{" "}
          <span className="text-blue-400">See photo guidelines.</span>.
        </div>
        <div className="flex justify-center">
          <div>
            <div className="grid grid-cols-12 gap-y-4">
              <div
                className={`col-span-12 max-w-[700px] min-w-[700px] rounded border p-4 ${
                  selected === 0 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(0)
                }}
              >
                <div className="font-bold text-lg ">Passport</div>
                <div className="text-gray-300">
                  Upload copy of your valid passport
                </div>
              </div>
              <div
                className={`col-span-12 max-w-[700px] min-w-[700px] rounded border p-4 ${
                  selected === 1 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(1)
                }}
              >
                <div className="font-bold text-lg ">Driving license</div>
                <div className="text-gray-300">
                  Upload a copy of your valid driving license
                </div>
              </div>

              <div
                className={`col-span-12 max-w-[700px] min-w-[700px] rounded border p-4 ${
                  selected === 2 ? "border-blue-400" : "border"
                } cursor-pointer`}
                onClick={() => {
                  setSelected(2)
                }}
              >
                <div className="font-bold text-lg ">Identity card</div>
                <div className="text-gray-300">
                  Upload a copy of your valid identity card
                </div>
              </div>

              <div
                className={`col-span-12 max-w-[700px] bg-yellow-100 rounded border p-4 border-none  cursor-pointer`}
                onClick={() => {
                  setSelected(1)
                }}
              >
                <div className="font-bold text-lg ">
                  Why do we need a proof of ID?
                </div>
                <div className="">
                  We recommend to ensure your account is secure and need this
                  document to verify all company representatives's identity.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Step7: React.FC = () => {
  return (
    <React.Fragment>
      <UploadPassport />
    </React.Fragment>
  )
}

const steps = [
  {
    id: 1,
    title: "Overview of fees",
    component: () => <Step1 />,
  },
  {
    id: 2,
    title: "Get ready to set up payment processing",
    component: () => <Step2 />,
  },
  {
    id: 3,
    title: "Enter billing details",
    component: () => <Step3 />,
  },
  {
    id: 4,
    title: "Add the registered business details",
    component: () => <Step4 />,
  },
  {
    id: 5,
    title: "Select ype of business ownership",
    component: () => <Step5 />,
  },
  {
    id: 6,
    title: "Select proof of ID to upload",
    component: () => <Step6 />,
  },
]

const PaymentView: React.FC = () => {
  const [step, setStep] = React.useState(1)
  useEffect(() => {
    console.log(step)
  }, [step])

  return (
    <React.Fragment>
      <MultiForm
        steps={steps.length}
        step={step}
        setStep={setStep}
        title={steps.filter((item) => item.id === step)[0]?.title}
      >
        {steps.filter((item) => item.id === step)[0]?.component()}
      </MultiForm>
    </React.Fragment>
  )
}

export default PaymentView
